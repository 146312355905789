import React from 'react';

import PartnerProfile from './PartnerProfile';
import classes from './PartnerList.module.css';

const PartnerList = props => {
	return (
		<div className={classes.PartnerList}>
			<h1>KENALI PARTNER ANDA</h1>
			<ul className={classes.PartnerListUnordered}>
				{props.partners &&
					props.partners.map(partner => (
						<PartnerProfile
							name={partner.name}
							key={partner.id}
							id={partner.id}
							title={partner.title}
							partnerDesc={partner.description}
							imgUrl={partner.image}
							imgOrder={partner.imgOrder}
							descOrder={partner.descOrder}
						/>
					))}
			</ul>
		</div>
	);
};

export default PartnerList;

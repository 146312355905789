import React, { useState, useEffect } from 'react';

import Modal from '../UI/Modal/Modal';

const withHttpErrorHandler = (WrappedComponent, axios) => {
	return props => {
		const [ errorState, setErrorState ] = useState(null);
		useEffect(
			() => {
				axios.interceptors.request.use(req => {
					setErrorState(null);
					return req;
				});
				axios.interceptors.response.use(
					res => res,
					error => {
						setErrorState(error);
					}
				);
			},
			[ setErrorState ]
		);

		const errorConfirmHandler = () => {
			setErrorState(null);
		};
		return (
			<React.Fragment>
				<Modal
					show={errorState}
					clicked={errorConfirmHandler}
					onCancel={errorConfirmHandler}>
					{errorState && errorState.message}
				</Modal>
				<WrappedComponent {...props} />
			</React.Fragment>
		);
	};
};

export default withHttpErrorHandler;

import React, { useEffect, useState, useCallback } from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { withRouter, Redirect } from 'react-router-dom';
import axios from '../../axios-articles';
import { useParams } from 'react-router-dom';
import { useForm } from '../../shared/hooks/form-hook';
import { VALIDATOR_REQUIRE } from '../../shared/util/validator';
import { useStore } from '../../store/store';

import Spinner from '../../shared/UI/Spinner/Spinner';
import Button from '../../shared/UI/Button/Button';

import Input from '../../shared/UI/Input/Input';
import classes from './ArticleEditForm.module.css';

const ArticleEditForm = props => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ redirector, setRedirector ] = useState(null);
	const [ editContent, setEditContent ] = useState(<Spinner />);
	const state = useStore()[0];
	const articleid = useParams().articleid;

	const [ formState, onInputHandler, setFormData ] = useForm(
		{
			title: {
				value: '',
				isValid: false
			},
			brief: {
				value: '',
				isValid: false
			},
			article: {
				value: '',
				isValid: true
			}
		},
		false
	);

	useEffect(
		() => {
			let mounted = true;
			axios.get(`/articles.json`).then(res => {
				if (mounted) {
					const identifiedArticle = res.data[articleid];
					setFormData(
						{
							title: {
								value: identifiedArticle.title,
								isValid: true
							},
							brief: {
								value: identifiedArticle.brief,
								isValid: true
							},
							article: {
								value: identifiedArticle.article,
								isValid: true
							}
						},
						true
					);
					setIsLoading(false);
				}
			});
			return () => (mounted = false);
		},
		[ articleid, setFormData ]
	);

	const ckEditor = useCallback(
		(event, editor) => {
			const articleData = editor.getData();
			console.log(editor);
			onInputHandler('article', articleData, true);
		},
		[ onInputHandler ]
	);
	const { isAuthenticated } = props;

	console.log(formState.isValid);
	useEffect(
		() => {
			let mounted = true;
			let timer = null;
			if (!isAuthenticated) {
				if (mounted) {
					timer = setTimeout(() => {
						setRedirector(<Redirect to='/' />);
					}, 100);
				}
			} else {
				setRedirector(null);
				if (!isLoading) {
					setEditContent(
						<React.Fragment>
							<Input
								id='title'
								inputtype='input'
								type='text'
								label='Title'
								value={formState.inputs.title.value}
								isValid={formState.inputs.title.isValid}
								validators={[ VALIDATOR_REQUIRE() ]}
								errortext='Please enter a valid title'
								onInput={onInputHandler}
							/>
							<Input
								id='brief'
								inputtype='textarea'
								label='Subject'
								rows='2'
								cols='30'
								value={formState.inputs.brief.value}
								isValid={formState.inputs.brief.isValid}
								onInput={onInputHandler}
								errortext='Please enter a valid Subject.'
								validators={[ VALIDATOR_REQUIRE() ]}
							/>
							<CKEditor
								editor={ClassicEditor}
								onChange={ckEditor}
								data={formState.inputs.article.value}
							/>

							<Button
								type='submit'
								btnwidth='5rem'
								btnheight='2rem'
								disabled={!formState.isValid}
								btnclasses={[ 'Form' ]}>
								Submit
							</Button>
						</React.Fragment>
					);
				}
			}
			return () => {
				mounted = false;
				clearTimeout(timer);
			};
		},
		[
			isAuthenticated,
			formState.inputs.title.value,
			formState.inputs.title.isValid,
			formState.inputs.brief.value,
			formState.inputs.brief.isValid,
			formState.inputs.article.value,
			formState.isValid,
			onInputHandler,
			isLoading,
			ckEditor
		]
	);

	const onSubmitHandler = event => {
		setIsLoading(true);
		event.preventDefault();
		const updatedArticle = {
			article: formState.inputs.article.value,
			title: formState.inputs.title.value,
			brief: formState.inputs.brief.value
		};
		axios
			.put(`/articles/${articleid}.json?auth=${state.authStats.idToken}`, updatedArticle)
			.then(() => {
				setIsLoading(false);
				props.history.goBack();
			})
			.catch(err => {
				setIsLoading(false);
				console.log(err);
			});
	};

	let fullContent = (
		<React.Fragment>
			{redirector}
			{editContent}
		</React.Fragment>
	);

	if (isLoading) {
		fullContent = <Spinner />;
	}

	return (
		<React.Fragment>
			<form className={classes.ArticleEditForm} onSubmit={onSubmitHandler}>
				{fullContent}
			</form>
		</React.Fragment>
	);
};

export default withRouter(ArticleEditForm);

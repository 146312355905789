import React from 'react';
import { Link } from 'react-router-dom';

import Avatar from '../../shared/UI/Avatar/Avatar';
import classes from './TentangKamiBrief.module.css';

import john from '../../assets/images/John.jpg';
import gaby from '../../assets/images/Gaby.jpg';
import risman from '../../assets/images/Risman.jpg';

const TentangKamiBrief = () => {
	return (
		<div className={classes.TentangKamiBrief}>
			<Link to='/tentang-kami'>
				<h2>KENALI PARTNER ANDA</h2>
			</Link>
			<p>
				Inti Dinamis bergerak dalam bidang jasa konsultan organisasi dan pengembangan sumber
				daya manusia. Didirikan tahun 2005 dengan dilandasi intensi untuk menjadi mitra
				terbaik bagi rekan bisnisnya dalam mengelola dan mengembangkan Human Capital untuk
				menjamin pencapaian visi yang dicanangkan.
			</p>
			<div className={classes.TentangKamiBriefAvatar}>
				<div>
					<Link to='/tentang-kami'>
						<Avatar imgUrl={gaby} />
						<label>GABY ANNIWATI</label>
					</Link>
				</div>
				<div>
					<Link to='/tentang-kami'>
						<Avatar imgUrl={john} />
						<label>JOHN ARIF PURBA</label>
					</Link>
				</div>
				<div>
					<Link to='/tentang-kami'>
						<Avatar imgUrl={risman} />
						<label>RISMAN PURBA</label>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default TentangKamiBrief;

import React from 'react';

import classes from './HubungiKami.module.css';

const HubungiKami = () => {
	return (
		<div className={classes.HubungiKami}>
			<h1>HUBUNGI KAMI</h1>
		</div>
	);
};

export default HubungiKami;

import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ProductContext } from '../context/product-context';

import ProdukJasaNav from '../components/ProdukJasa/ProdukJasaNav';
import ContentDesc from '../shared/ContentDesc/ContentDesc';

import classes from './ProdukJasaDetails.module.css';

const ProdukJasaDetails = props => {
	useEffect(() => window.scrollTo(0, 0));
	const productId = useParams().produkid;
	const productContext = useContext(ProductContext);
	const identifiedProduct = productContext.products.find(prod => prod.id === productId);
	return (
		<div className={classes.ProdukJasaDetails}>
			<ContentDesc
				title={identifiedProduct.productName}
				backgroundImage={identifiedProduct.backgroundImage}
			/>
			<ProdukJasaNav products={productContext.products} />
			<div className={classes.ProdukJasaDetailsContent}>
				{identifiedProduct.content.par.map((p, index) => (
					<React.Fragment key={index}>{p}</React.Fragment>
				))}
			</div>
		</div>
	);
};

export default ProdukJasaDetails;

import React from 'react';
import classes from './GaleriFrame.module.css';

const GaleriFrame = props => {
	return (
		<div className={classes.GaleriFrame}>
			<img src={props.backgroundImage} alt={props.name} />
		</div>
	);
};

export default GaleriFrame;

import React, { useContext, useEffect } from 'react';

import ContentDesc from '../shared/ContentDesc/ContentDesc';
import ProdukJasaList from '../components/ProdukJasa/ProdukJasaList';
import { ProductContext } from '../context/product-context';

import classes from './ProdukJasa.module.css';
import PJBG from '../assets/BGContentDesc/BG_Produk&Jasa.jpg';

const ProdukJasa = () => {
	useEffect(() => window.scrollTo(0, 0));
	const title = 'PRODUK & JASA';
	const description = `Produk yang kami kembangkan bermuara pada upaya menciptakan sumber daya manusia yang menjadi
	kontributor hebat di perusahaan anda. Kami menyediakan berbagai layanan untuk membantu
	mengembangkan Human Capital dengan layanan berkualitas tinggi yang bisa dikelompokkan menjadi:`;

	const productContext = useContext(ProductContext);
	return (
		<div className={classes.ProdukJasa}>
			<ContentDesc
				title={title}
				description={description}
				backgroundImage={PJBG}
				isGradient
			/>
			<ProdukJasaList products={productContext.products} />
		</div>
	);
};

export default ProdukJasa;

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import MainNavigation from './shared/Navigation/MainNavigation';
import Homepage from './containers/Homepage';
import TentangKami from './containers/TentangKami';
import KontakKami from './containers/KontakKami';
import Pengalaman from './containers/Pengalaman';
import Galeri from './containers/Galeri';
import ProdukJasa from './containers/ProdukJasa';
import ProdukJasaDetails from './containers/ProdukJasaDetails';
import Articles from './containers/Articles';
import ArticleDetails from './containers/ArticleDetails';
import ArticleNew from './containers/ArticleNew';
import ArticleEdit from './containers/ArticleEdit';
import Footer from './shared/Footer/Footer';
import AdminAuth from './containers/AdminAuth';
import Logout from './containers/Logout';
import WAemail from './shared/UI/WA_email/WA_email';
import { useStore } from './store/store';

import { ProductContextProvider } from './context/product-context';

import './App.css';

const App = props => {
	const [ state, dispatch ] = useStore();
	const isAuthenticated = state.authStats.idToken !== null;
	useEffect(
		() => {
			const clearLS = () => {
				localStorage.removeItem('idToken');
				localStorage.removeItem('expireMoment');
				localStorage.removeItem('userId');
				console.log('Cleaning...');
			};

			const idToken = localStorage.getItem('idToken');

			if (!idToken) {
				console.log('idToken not found');
				dispatch('AUTH_LOGOUT');
				clearLS();
			} else {
				const expireMoment = localStorage.getItem('expireMoment');
				if (new Date(expireMoment) < new Date()) {
					console.log('ExpireMoment has passed');
					dispatch('AUTH_LOGOUT');
					clearLS();
				} else {
					const remainingTime =
						(new Date(expireMoment).getTime() - new Date().getTime()) / 1000; // Result converted to seconds
					const userId = localStorage.getItem('userId');
					dispatch('AUTH_SUCCESS', { idToken, userId });
					setTimeout(() => {
						dispatch('AUTH_LOGOUT');
						clearLS();
					}, remainingTime * 1000);
				}
			}
		},
		[ dispatch ]
	);

	return (
		<Router>
			<WAemail />
			<MainNavigation isAuthenticated={isAuthenticated} />
			<main>
				<Switch>
					<Route path='/' exact component={Homepage} />
					<Route path='/tentang-kami' component={TentangKami} />
					<Route path='/kontak-kami' component={KontakKami} />
					<Route path='/pengalaman' component={Pengalaman} />
					<Route path='/galeri' component={Galeri} />
					<Route
						path='/articles/new'
						render={props => (
							<ArticleNew {...props} isAuthenticated={isAuthenticated} />
						)}
					/>
					<Route
						path='/articles/:articleid/edit'
						render={props => (
							<ArticleEdit {...props} isAuthenticated={isAuthenticated} />
						)}
					/>
					<Route path='/articles/:articleid' component={ArticleDetails} />
					<Route path='/articles/' component={Articles} />
					<Route path='/adminIntiDinamis/' component={AdminAuth} />
					<Route path='/logout' component={Logout} />
					<ProductContextProvider>
						<Switch>
							<Route path='/produk-jasa/:produkid' component={ProdukJasaDetails} />
							<Route path='/produk-jasa' component={ProdukJasa} />
							<Redirect to='/' />
						</Switch>
					</ProductContextProvider>
				</Switch>
			</main>
			<Footer />
		</Router>
	);
};

export default App;

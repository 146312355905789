import React from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import classes from './SideDrawer.module.css';

const SideDrawer = props => {
	const sideDrawer = (
		<CSSTransition
			mountOnEnter
			unmountOnExit
			in={props.show}
			timeout={400}
			classNames={'drawer'}>
			<aside className={classes.SideDrawer} onClick={props.onClick}>
				{props.children}
			</aside>
		</CSSTransition>
	);
	return ReactDOM.createPortal(sideDrawer, document.getElementById('drawer-hook'));
};

export default SideDrawer;

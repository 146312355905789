import React, { useEffect } from 'react';

import classes from './MainHeader.module.css';

const MainHeader = props => {
	useEffect(() => {
		window.onscroll = function (){
			scrollFunction();
		};

		function scrollFunction (){
			if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
				document.querySelector(`.${classes.MainHeader}`).classList.add(classes.Scrolling);
			} else {
				document
					.querySelector(`.${classes.MainHeader}`)
					.classList.remove(classes.Scrolling);
			}
		}
	});
	return <header className={classes.MainHeader}>{props.children}</header>;
};

export default MainHeader;

import React from 'react';
import GaleriFrame from './GaleriFrame';
import classes from './GaleriList.module.css';

const GaleriList = props => {
	return (
		<div className={classes.GaleriList}>
			{props.galeri.map(gal => (
				<GaleriFrame key={gal.name} name={gal.name} backgroundImage={gal.backgroundImage} />
			))}
		</div>
	);
};

export default GaleriList;

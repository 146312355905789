import React, { useState, useEffect } from 'react';
import Carousel from 'react-elastic-carousel';

import ArticleCard from './ArticleCard';
import classes from './ArticleCarousel.module.css';

const ArticleCarousel = props => {
	const [ toShow, setToShow ] = useState(3);

	useEffect(
		() => {
			if (props.articles) {
				props.articles.length === 1
					? setToShow(1)
					: props.articles.length === 2 ? setToShow(2) : setToShow(3);
			}
		},
		[ props.articles, setToShow ]
	);

	const configCarousel = {
		itemsToShow: toShow,
		itemPadding: [ 10, 20 ],
		className: classes.IntiDinamis,
		infinite: true,
		breakPoints: [
			{ width: 1, itemsToShow: 1 },
			{ width: 550, itemsToShow: toShow, itemsToScroll: 1 },
			{ width: 850, itemsToShow: toShow },
			{ width: 1150, itemsToShow: toShow, itemsToScroll: 2 },
			{ width: 1450, itemsToShow: toShow },
			{ width: 1750, itemsToShow: toShow }
		]
	};

	let articleList = (
		<h2 style={{ color: 'rgb(85, 85, 85)' }}>(...No Articles Has Been Posted Yet...)</h2>
	);

	if (props.articles.length > 0) {
		articleList = (
			<Carousel {...configCarousel}>
				{props.articles &&
					props.articles.map(art => (
						<ArticleCard
							key={art.id}
							articleId={art.id}
							title={art.title}
							brief={art.brief}
							article={art.article}
							onDelete={props.onDelete}
						/>
					))}
			</Carousel>
		);
	}
	return <div className={classes.ArticleCarousel}>{articleList}</div>;
};

export default ArticleCarousel;

import React from 'react';

import classes from './Button.module.css';

const Button = props => {
	const btnClass = [ classes.Button ];
	props.btnclasses && props.btnclasses.map(cls => btnClass.push(classes[cls]));
	return (
		<button
			className={btnClass.join(' ')}
			style={{
				width: props.btnwidth && props.btnwidth,
				height: props.btnheight && props.btnheight
			}}
			disabled={props.disabled}
			onClick={props.onClick}>
			{props.children}
		</button>
	);
};

export default Button;

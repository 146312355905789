import React, { useState } from 'react';

import PLC_BG from '../assets/BGContentDesc/BG_PDJ_Coaching.jpg';
import TRN_BG from '../assets/BGContentDesc/BG_PDJ_Training.jpg';
import BRP_BG from '../assets/BGContentDesc/BG_PDJ_Brain_Power.jpg';
import PAS_BG from '../assets/BGContentDesc/BG_PDJ_Assessment.jpg';
import HROD_BG from '../assets/BGContentDesc/BG_PDJ_HROD_Consulting.jpg';
import REC_BG from '../assets/BGContentDesc/BG_PDJ_Recruitment.jpg';
import EO_BG from '../assets/BGContentDesc/BG_PDJ_EventOrganizing.jpg';
import classes from './product-context.module.css';

export const ProductContext = React.createContext({
  products: [{ productName: '', id: '', content: '' }],
  updateProduct: () => {},
});

export const ProductContextProvider = (props) => {
  const productState = useState([
    {
      id: 'performance',
      productName: 'Performance & Leadership Coaching',
      backgroundImage: PLC_BG,
      content: {
        header: 'PERFORMANCE & LEADERSHIP COACHING',
        brief: `Coaching merupakan kegiatan pendampingan antara seorang coach dengan coachee 
					untuk memaksimalkan potensi dalam mencapai target yang ditetapkan. Dengan metode 
					coaching potensi terpendam seseorang dapat keluar dan diaktualisasikan secara maksimal 
					untuk...`,
        par: [
          <p>
            Coaching merupakan kegiatan pendampingan antara seorang coach dengan coachee untuk memaksimalkan potensi
            dalam mencapai target yang ditetapkan. Dengan metode coaching{' '}
            <span style={{ fontWeight: 'bold', color: 'salmon' }}>
              potensi terpendam seseorang dapat keluar dan diaktualisasikan
            </span>{' '}
            secara maksimal untuk mencapai tujuan organisasi. Metode ini telah terbukti sangat efektif dalam
            mengembangkan manusia menjadi pemimpin dengan kinerja hebat.
          </p>,
          <p>
            Coaching telah menjadi metode pengembangan potensi karyawan yang paling muktahir saat ini di dunia. Coaching
            sudah banyak digunakan berbagai perusahaan sebagai metode wajib dan menjadi{' '}
            <span style={{ fontWeight: 'bold', color: 'salmon' }}>
              budaya perusahaan dalam mengembangkan para pemimpinnya
            </span>
            . Bahkan beberapa perusahaan dunia menyediakan ‘pool of coach’ untuk melayani kebutuhan coaching
            karyawannya.
          </p>,
          <p
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              fontSize: '1.7rem',
              color: 'rgb(85,85,85)',
            }}>
            COACHING MEMAKSIMALKAN POTENSI & MEMBUKA SUMBER PRODUKTIFITAS YANG TERPENDAM
          </p>,
          <p>
            Kegiatan <span style={{ fontWeight: 'bold', color: 'salmon' }}>coaching yang bersifat individual</span>{' '}
            sangat cocok bagi para calon pemimpin maupun pemimpin, baik pemimpin perusahaan, pemimpin unit bisnis,
            pemimpin cabang, pemimpin divisi atau departement. dapat dilakukan pada sekelompok team sales, team
            produksi, team proyek, atau team yang akan menghadapi suatu kompetisi tertentu.
          </p>,
          <p>
            Coaching memberi banyak keuntungan seperti munculnya perspektif baru terhadap tantangan, peningkatan
            kualitas keputusan, kualitas hubungan dengan orang lain, rasa percaya diri, dan masih banyak lagi benefit
            lainnya.
          </p>,
          <p>Coaching yang kami berikan mencakup:</p>,
          <ol className={classes.ListItems}>
            <li>Coaching for Leadership Development</li>
            <li>Coaching for Performance Development</li>
            <li>Coaching for Character Development</li>
            <li>Coaching for Mentality Development</li>
            <li>Coaching for Teamwork</li>
            <li>Coaching for Group Performance</li>
          </ol>,
        ],
      },
    },
    {
      id: 'training',
      productName: 'Training (Pelatihan dan Pengembangan SDM)',
      backgroundImage: TRN_BG,
      content: {
        header: 'PENGEMBANGAN SDM',
        brief: `Didukung dengan tenaga pelatih, pengajar, dan fasilitator yang profesional
				kami menyediakan berbagai kegiatan pelatihan berkualitas tinggi. Kegiatan
				pelatihan dan pengembangan baik yang bersifat in-door maupun outdoor kami
				design dengan menggunakan teknologi...`,
        par: [
          <p>
            Didukung dengan tenaga pelatih, pengajar, dan fasilitator yang profesional kami menyediakan berbagai
            kegiatan pelatihan berkualitas tinggi. Kegiatan pelatihan dan pengembangan baik yang bersifat in-door maupun
            outdoor kami design dengan menggunakan teknologi quantum learning.
          </p>,
          <p>
            Modul pelatihan dan pengembangan yang kami siapkan terdiri atas dua kelompok. Kelompok pertama berupa
            pelatihan yang bertujuan meningkatkan pengetahuan pada bidang tertentu antara lain:
          </p>,
          <ol className={classes.ListItems}>
            <li>Coaching Skill for Leader</li>
            <li>How to be a good Mentor</li>
            <li>Great Leader Development Program</li>
            <li>Supervisor Development Program</li>
            <li>Salesman Development Program</li>
            <li>Powerfull Communication Skill</li>
            <li>Essential Selling Skill</li>
            <li>Service Mentality Development</li>
            <li>HR Professional Development Program</li>
            <li>Teknik Wawancara yang Efektif</li>
          </ol>,
          <p>
            Selain pelatihan yang bertujuan meningkatkan pengetahuan pada bidang tertentu modul lainnya adalah pelatihan
            dan pengembangan yang bertujuan mengubah mindset, pola pikir, mentalitas, maupun perilaku tertentu.
            Pelatihan pada aspek mentalitas sangat penting dilakukan secara periodik pada setiap karyawan. Dari tiga
            faktor sukses seseorang dalam pekerjaan, selain pengetahuan dan kemampuan, aspek sikap mental memegang
            pengaruh paling besar.
          </p>,
          <p>Kami menyediakan berbagai pendekatan untuk aspek mental atau mindset antara lain:</p>,
          <ol className={classes.ListItems}>
            <li>Team Building</li>
            <li>Basic Mentality Development</li>
            <li>Mindset Alignment to Organization Values</li>
            <li>Winning Mentality</li>
            <li>Capacity Building</li>
            <li>People Development on the Field</li>
          </ol>,
        ],
      },
    },
    {
      id: 'consultant',
      productName: 'HR & OD Consulting',
      backgroundImage: HROD_BG,
      content: {
        header: 'HR & OD CONSULTING',
        brief: `Bentuk layanan ini kami design dengan menggunakan metodologi yang melibatkan
				peran aktif dari pihak klien untuk menjamin kelancaran proses implementasi
				hasil kegiatan konsultasi. Metodologi ini terbukti sangat efektif baik dari
				segi kualitas sistem yang dihasilkan...`,
        par: [
          <p>
            Layanan yang kami sediakan untuk Jasa Konsultasi Manajemen fokus pada efi siensi dan efektifi tas sistem
            manajemen khususnya dalam hal pengelolaan suber daya manusia.
          </p>,
          <p>
            Bentuk layanan ini kami design dengan menggunakan metodologi yang melibatkan peran aktif dari pihak klien
            untuk menjamin kelancaran proses implementasi hasil kegiatan konsultasi. Metodologi ini terbukti sangat
            efektif baik dari segi kualitas sistem yang dihasilkan maupun dari segi kemudahan implementasinya.
          </p>,
          <p>Lingkup jasa layanan konsultasi manajemen yang kami sediakan antara lain:</p>,
          <ol className={classes.ListItems}>
            <li>Business Strategy Planning</li>
            <li>Business Strategy Management</li>
            <li>Organization Development</li>
            <li>Core Values Development</li>
            <li>Culture Development</li>
            <li>Performance Management System Development</li>
            <li>Human Capital Management System Development</li>
            <li>Human Resource Strategy Map / HR Score Card</li>
            <li>Executive Management System Development</li>
            <li>Salary Structure, Grading System, Job Evaluation</li>
            <li>Change Management Program</li>
            <li>Outsourcing of HR Function</li>
          </ol>,
        ],
      },
    },
    {
      id: 'potential',
      productName: 'Potential Assessment',
      backgroundImage: PAS_BG,
      content: {
        header: 'POTENTIAL ASSESSMENT',
        brief: `Mengukur potensi dan kompetensi setiap karyawan merupakan kegiatan yang harus 
				dilakukan secara berkala. Hasil pengukuran tersebut diperlukan sebagai dasar dalam menyusun 
				arah karir karyawan dan rencana pengembangan karyawan. Ketajaman hasil pengukuran potensi 
				karyawan menjadi faktor penting yang...`,
        par: [
          <p>
            Mengukur potensi dan kompetensi setiap karyawan merupakan kegiatan yang{' '}
            <span style={{ fontWeight: 'bold', color: 'salmon' }}>harus dilakukan secara berkala</span>. Hasil
            pengukuran tersebut diperlukan sebagai dasar dalam menyusun arah karir karyawan dan rencana pengembangan
            karyawan. Ketajaman hasil pengukuran potensi karyawan menjadi faktor penting yang mempengaruhi tepat atau
            tidaknya keputusan manajemen dalam menempatkan seseorang di posisi tertentu.
          </p>,
          <p>
            Metode dan alat pengukuran yang handal serta kemampuan tenaga assessor professional yang kami miliki telah
            terbukti mampu membantu manajemen dalam menetapkan arah karir karyawannya secara lebih tepat.
          </p>,
          <p>
            Untuk lingkup Potential Assessment atau kegiatan pengukuran potensi, kami menyediakan jasa antara lain:
          </p>,
          <ol className={classes.ListItems}>
            <li>Penyusunan kriteria potensi dan soft competency</li>
            <li>Pelaksanaan kegiatan Potential Assessment</li>
            <li>Pemberian umpan balik kepada karyawan</li>
          </ol>,
        ],
      },
    },
    {
      id: 'recruit',
      productName: 'Recruitment',
      backgroundImage: REC_BG,
      content: {
        header: 'RECRUITMENT',
        brief: `Recruitment merupakan hal penting bagi setiap organisasi karena akan sangat
				menentukan kualitas tenaga kerja yang akan masuk dalam organisasi. Seleksi
				karyawan baru harus dilakukan dengan sangat baik agar setiap karyawan yang
				masuk selalu memberi kontribusi maksimum bagi organisasi. Kami menyediakan...`,
        par: [
          <p>
            Recruitment merupakan hal penting bagi setiap organisasi karena akan sangat menentukan kualitas tenaga kerja
            yang akan masuk dalam organisasi. Seleksi karyawan baru harus dilakukan dengan sangat baik agar setiap
            karyawan yang masuk selalu memberi kontribusi maksimum bagi organisasi.
          </p>,
          <p>
            Kami menyediakan layanan jasa untuk pelaksanaan kegiatan rekrutmen dengan menggunakan berbagai alat
            pengukuran potensi dan kompetensi handal yang disesuaikan dengan dimensi pengukuran kualitas sumberdaya
            manusia yang ingin direkrut.
          </p>,
          <p>
            Aspek pengukuran dalam kegiatan rekrutmen dapat meliputi aspek yang bersifat kognitif, kepribadian, dan
            kompetensi dasar dalam melaksanakan suatu pekerjaan.
          </p>,
          <p>Jasa kegiatan rekrutmen yang kami sediakan antara lain :</p>,
          <ol className={classes.ListItems}>
            <li>Psikotest calon karyawan.</li>
            <li>Pelaksanaan Walk-in Interview.</li>
            <li>Seleksi berkas lamaran kerja.</li>
          </ol>,
        ],
      },
    },

    {
      id: 'brainpower',
      productName: 'Brain Power',
      backgroundImage: BRP_BG,
      content: {
        header: 'BRAIN POWER',
        brief: `Brain Power merupakan salah satu teknologi unggulan yang kami kembangkan
				dari hasil pengalaman selama belasan tahun dalam meningkatkan kemampuan
				dasar manusia. Teknologi Brain Power dikembangkan berbasis pada berbagai
				teori ilmiah mengenai kekuatan pikiran manusia antara lain...`,
        par: [
          <p>
            Brain Power merupakan salah satu teknologi unggulan yang kami kembangkan dari hasil pengalaman selama
            belasan tahun dalam meningkatkan kemampuan dasar manusia. Teknologi Brain Power dikembangkan berbasis pada
            berbagai teori ilmiah mengenai kekuatan pikiran manusia antara lain ilmu psikologi, ilmu sosial, ilmu medis
            atau biologis, dan ilmu fisika.
          </p>,
          <p>
            Teknologi Brain Power kami kemas dalam berbagai produk lokakarya untuk meningkatkan kemampuan setiap peserta
            menggali potensi dalam dirinya yang selama ini ada namun belum disadari atau belum dimanfaatkan secara
            maksimal. Pengalaman kami diberbagai perusahaan telah membuktikan kedasyatan teknologi{' '}
            <span style={{ fontWeight: 'bold', color: 'salmon' }}>
              Brain Power mampu memberikan peningkatan signifikan terhadap hasil kerja
            </span>{' '}
            dari setiap peserta setelah mengikuti lokakarya.
          </p>,
          <p>Beberapa modul lokakarya yang kami siapkan dengan menggunakan teknologi Brain Power antara lain:</p>,
          <ol className={classes.ListItems}>
            <li>Motivation with Brain Power</li>
            <li>Teamwork with Brain Power</li>
            <li>Salesmanship with Brain Power</li>
            <li>Service Mentality with Brain Power</li>
            <li>Self Confidence with Brain Power</li>
            <li>Cohesiveness with Brain Power</li>
            <li>Self Awareness with Brain Power</li>
          </ol>,
        ],
      },
    },
    {
      id: 'event',
      productName: 'Event Organizing',
      backgroundImage: EO_BG,
      content: {
        header: 'EVENT ORGANIZING',
        brief: `Kami berpengalaman memfasilitasi berbagai kegiatan penting. Kualitas
				pengelolaan kegiatan organisasi anda akan ditangani oleh tenaga-tenaga
				professional yang menjamin seluruh aspek ditel memberi sumbangan maksimal
				terhadap tujuan kegiatan. Kami berpengalaman memfasilitasi...`,
        par: [
          <p>
            Kami juga menyediakan jasa untuk mengelola event-event penting bagi organisasi. Kami mengemas acara yang
            disesuaikan dengan budaya perusahaan dan keiningan unik dari manajemen dengan tetap fokus pada tujuan dari
            masingmasing kegiatan.
          </p>,
          <p>
            Kami berpengalaman memfasilitasi berbagai kegiatan penting. Kualitas pengelolaan kegiatan organisasi anda
            akan ditangani oleh tenaga-tenaga professional yang menjamin seluruh aspek ditel memberi sumbangan maksimal
            terhadap tujuan kegiatan.
          </p>,
          <p>Beberapa event yang sering kami fasilitasi antara lain adalah:</p>,
          <ol className={classes.ListItems}>
            <li>Employee Gathering</li>
            <li>Management Gathering</li>
            <li>Family Day</li>
            <li>Annual Kick-Off</li>
          </ol>,
        ],
      },
    },
  ])[0];

  const updateProductHandler = () => {};

  return (
    <ProductContext.Provider value={{ products: productState, updateProduct: updateProductHandler }}>
      {props.children}
    </ProductContext.Provider>
  );
};

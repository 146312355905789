import React, { useState, useEffect } from 'react';
import axios from '../axios-articles';
import { useStore } from '../store/store';

import ArtBG from '../assets/BGContentDesc/BG_PDJ_EventOrgs.jpg';
import ContentDesc from '../shared/ContentDesc/ContentDesc';
import ArticleCard from '../components/Articles/ArticleCard';
import Spinner from '../shared/UI/Spinner/Spinner';
import classes from './Articles.module.css';

const Articles = props => {
	useEffect(() => window.scrollTo(0, 0));
	const [ state, dispatch ] = useStore();
	const [ isLoading, setIsLoading ] = useState(true);
	const [ refresh, setRefresh ] = useState(true);

	useEffect(
		() => {
			let mounted = true;
			axios
				.get('/articles.json')
				.then(res => {
					if (mounted) {
						dispatch('SET_ARTICLE', res.data);
						setIsLoading(false);
					}
				})
				.catch(err => {
					console.log(err);
					setIsLoading(false);
				});
			return () => (mounted = false);
		},
		[ dispatch, setIsLoading, refresh ]
	);

	let articles = <Spinner />;

	if (!isLoading) {
		articles = (
			<div className={classes.Articles}>
				{[ ...state.articles.reverse() ].map(art => (
					<ArticleCard
						key={art.id}
						articleId={art.id}
						title={art.title}
						brief={art.brief}
						article={art.article}
						onDelete={() => setRefresh(prevState => !prevState)}
					/>
				))}
			</div>
		);
	}
	return (
		<React.Fragment>
			<ContentDesc backgroundImage={ArtBG} title='ARTIKEL' isGradient />

			{articles}
		</React.Fragment>
	);
};

export default Articles;

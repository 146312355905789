import React from 'react';
import { Link } from 'react-router-dom';

import classes from './HowCanWeHelp.module.css';
import Button from '../../shared/UI/Button/Button';
import consultancy from '../../assets/images/consultancy.jpg';

const HowCanWeHelp = () => {
	return (
		<div className={classes.HowCanWeHelp}>
			<div className={classes.ContentHowCanWeHelp}>
				<h3>BAGAIMANA KAMI MEMBANTU ANDA?</h3>
				<p>
					Mengelola bisnis tidak akan pernah lepas dari mengelola sumber daya manusia.
					Inti Dinamis mengaplikasikan metodologi pengembangan Human Capital dengan basis
					aspek mindset, mentalitas, pola pikir, nilai nilai, dinamika psikologis, dan
					dinamika perilaku
				</p>
				<div className={classes.ButtonHowCanWeHelp}>
					<Link to='/produk-jasa'>
						<Button btnwidth='7rem' btnheight='2.2rem'>
							CARI TAHU
						</Button>
					</Link>
				</div>
			</div>
			<div className={classes.ImageHowCanWeHelp}>
				<img src={consultancy} alt='consultancy' />
			</div>
		</div>
	);
};

export default HowCanWeHelp;

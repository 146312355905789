import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useStore } from '../store/store';

const Logout = () => {
	const dispatch = useStore()[1];
	useEffect(() => {
		dispatch('AUTH_LOGOUT');
		localStorage.removeItem('idToken');
		localStorage.removeItem('expireMoment');
		localStorage.removeItem('userId');
	});
	return <Redirect to='/' />;
};

export default Logout;

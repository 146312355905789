import React, { useEffect } from 'react';

import ArticleEditForm from '../components/Articles/ArticleEditForm';
import classes from './ArticleEdit.module.css';

const ArticleEdit = props => {
	useEffect(() => window.scrollTo(0, 0));

	return (
		<div className={classes.ArticleEdit}>
			<ArticleEditForm isAuthenticated={props.isAuthenticated} />
		</div>
	);
};

export default ArticleEdit;

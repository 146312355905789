import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useStore } from '../store/store';

import Article from '../components/Articles/Article';

const ArticleDetails = () => {
	useEffect(() => window.scrollTo(0, 0));
	const articleId = useParams().articleid;

	const state = useStore()[0];
	const filteredArticle = { ...state.articles.filter(art => art.id === articleId)[0] };

	return (
		<Article
			id={filteredArticle.id}
			title={filteredArticle.title}
			article={filteredArticle.article}
			brief={filteredArticle.brief}
			featuredArts={[ ...state.articles ]}
		/>
	);
};

export default ArticleDetails;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import MainHeader from './MainHeader';
import Backdrop from '../UI/Backdrop/Backdrop';
import NavLinks from './NavLinks';
import Logo from '../UI/Logo/Logo';
import MenuBtn from '../UI/MenuBtn/MenuBtn';
import SideDrawer from '../Navigation/SideDrawer';
import classes from './MainNavigation.module.css';

const MainNavigation = props => {
	const [ drawerIsOpen, setDrawerIsOpen ] = useState(false);

	const toggleDrawer = () => {
		setDrawerIsOpen(prevState => !prevState);
	};

	return (
		<React.Fragment>
			{drawerIsOpen && <Backdrop onClick={toggleDrawer} />}

			<SideDrawer show={drawerIsOpen} onClick={toggleDrawer}>
				<nav className={classes.NavigationDrawer}>
					<NavLinks isAuthenticated={props.isAuthenticated} />
				</nav>
			</SideDrawer>

			<MainHeader>
				<Link to='/'>
					<Logo width='7rem' />
				</Link>
				<nav className={classes.NavigationNavbar}>
					<NavLinks isAuthenticated={props.isAuthenticated} />
				</nav>
				<MenuBtn onClick={toggleDrawer} />
			</MainHeader>
		</React.Fragment>
	);
};

export default MainNavigation;

import React from 'react';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import classes from './Article.module.css';

const Article = props => {
	let featured = props.featuredArts.filter(art => art.id !== props.id);
	return (
		<div className={classes.ArticleContainer}>
			<div className={classes.Article}>
				<h1>{props.title}</h1>
				{ReactHtmlParser(props.article)}
			</div>
			<div className={classes.ArticleFeatured}>
				<h3>Featured Articles</h3>
				{featured.map(art => (
					<Link key={art.id} to={`/articles/${art.id}`}>
						{art.title}
					</Link>
				))}
			</div>
		</div>
	);
};

export default Article;

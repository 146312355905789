import React from 'react';

import classes from './MisiIntiDinamis.module.css';

const MisiIntiDinamis = () => {
  return (
    <div className={classes.MisiIntiDinamis}>
      <h2>MISI INTI DINAMIS</h2>
      <p>
        "Meningkatkan kualitas <em>Human Capital</em> menjadi kontributor hebat"
      </p>
    </div>
  );
};

export default MisiIntiDinamis;

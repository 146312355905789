import React, { useEffect, useState } from 'react';
import axios from '../axios-articles';
import background from '../assets/homepage/Background.png';

import Intro from '../components/Homepage/Intro';
import HowCanWeHelp from '../components/Homepage/HowCanWeHelp';
import TentangKamiBrief from '../components/Homepage/TentangKamiBrief';
import ArticleCarousel from '../components/Articles/ArticleCarousel';
import { useStore } from '../store/store';
import classes from './Homepage.module.css';
import Spinner from '../shared/UI/Spinner/Spinner';

const Homepage = props => {
	// useEffect(() => window.scrollTo(0, 0));
	const [ state, dispatch ] = useStore();
	const [ isLoading, setIsLoading ] = useState(true);
	const [ refresh, setRefresh ] = useState(true);

	useEffect(
		() => {
			axios
				.get('/articles.json')
				.then(res => {
					dispatch('SET_ARTICLE', res.data);
					setIsLoading(false);
				})
				.catch(err => {
					console.log(err);
					setIsLoading(false);
				});
		},
		[ dispatch, setIsLoading, refresh ]
	);

	let carousel = <Spinner />;

	if (!isLoading) {
		carousel = (
			<ArticleCarousel
				articles={[ ...state.articles.reverse() ]}
				onDelete={() => setRefresh(prevState => !prevState)}
			/>
		);
	}

	return (
		<div className={classes.Homepage} style={{ backgroundImage: `url(${background})` }}>
			<Intro />
			<HowCanWeHelp />
			{carousel}
			<TentangKamiBrief />
		</div>
	);
};

export default Homepage;

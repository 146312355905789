import React from 'react';

import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import classes from './WA_email.module.css';

const WA_email = () => {
	return (
		<div className={classes.WA_email}>
			<a
				href={`https://wa.me/62811855212?text=Semangat%20pagi%20Inti%20Dinamis!`}
				target='_blank'
				rel='noopener noreferrer'>
				<WhatsAppIcon className={classes.WhatsAppIcon} />
			</a>
			<a href='mailto:john.purba@intidinamis.com'>
				<MailOutlineIcon className={classes.MailOutlineIcon} />
			</a>
		</div>
	);
};

export default WA_email;

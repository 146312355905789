import React from 'react';

import Input from '../../shared/UI/Input/Input';
import Button from '../../shared/UI/Button/Button';
import classes from './ContactForm.module.css';

const ContactForm = () => {
	// const onSubmitHandler = event => {
	// 	event.preventDefault();
	// };

	return (
		<div className={classes.ContactForm}>
			<form>
				<Input
					inputtype='input'
					type='text'
					name='name'
					placeholder='Nama'
					validators={[]}
					isValid
				/>
				<Input
					inputtype='input'
					type='email'
					name='email'
					placeholder='Email'
					validators={[]}
					isValid
				/>
				<Input
					inputtype='input'
					type='text'
					name='subject'
					placeholder='Subject'
					validators={[]}
					isValid
				/>
				<Input
					inputtype='textarea'
					name='message'
					rows='12'
					cols='50'
					placeholder='Pesan Anda...'
					validators={[]}
					isValid
				/>
				<Button btnwidth='7rem' btnheight='2.5rem' btnclasses={[ 'Form' ]}>
					<a
						href='mailto:john.purba@intidinamis.com'
						style={{ textDecoration: 'none', color: 'rgb(85,85,85)' }}>
						Submit
					</a>
				</Button>
			</form>
		</div>
	);
};

export default ContactForm;

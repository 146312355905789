import React, { useState } from 'react';
import axios from '../../axios-articles';
import { Link } from 'react-router-dom';
import { useStore } from '../../store/store';
// import parse from 'html-react-parser';

import Spinner from '../../shared/UI/Spinner/Spinner';
import Button from '../../shared/UI/Button/Button';
import Book from '../../assets/images/Book.jpg';
import Modal from '../../shared/UI/Modal/Modal';
import classes from './ArticleCard.module.css';

const ArticleCard = props => {
	const [ showConfirmDelete, setShowConfirmDelete ] = useState(false);
	const state = useStore()[0];
	const [ isLoading, setIsLoading ] = useState(false);

	const openDeleteHandler = () => setShowConfirmDelete(true);
	const closeDeleteHandler = () => setShowConfirmDelete(false);
	const confirmDeleteHandler = async () => {
		setIsLoading(true);
		try {
			await axios.delete(`/articles/${props.articleId}.json?auth=${state.authStats.idToken}`);
			setIsLoading(false);
			setShowConfirmDelete(false);
			props.onDelete();
		} catch (err) {
			console.log(err);
			setIsLoading(false);
			setShowConfirmDelete(false);
		}
	};

	let deleting = (
		<React.Fragment>
			<Button
				onClick={closeDeleteHandler}
				btnwidth='5rem'
				btnheight='2rem'
				btnclasses={[ 'Edit' ]}>
				CANCEL
			</Button>
			<Button onClick={confirmDeleteHandler} btnwidth='5rem' btnheight='2rem'>
				DELETE
			</Button>
		</React.Fragment>
	);

	if (isLoading) {
		deleting = (
			<div className={classes.spinnerContainer}>
				<Spinner />;
			</div>
		);
	}

	return (
		<React.Fragment>
			<Modal
				show={showConfirmDelete}
				onCancel={closeDeleteHandler}
				header='Confirm Delete Article'
				footer={deleting}>
				Are you sure you want to delete this article?
			</Modal>
			<div className={`${classes.ArticleCard} ${classes[props.addClass]}`}>
				<div className={classes.ArticleImg} style={{ backgroundImage: `url(${Book})` }} />
				<div className={classes.ArticleContent}>
					<Link to={`/articles/${props.articleId}`}>
						<h4>{props.title}</h4>
					</Link>
					<div
						style={{
							display: 'flex',
							alignItems: 'self-start',
							height: '100%',
							marginTop: '1rem'
						}}>
						{props.brief && props.brief.split(' ').slice(0, 15).join(' ') + '...'}
					</div>
					{state.authStats.idToken !== null && (
						<div className={classes.ButtonGroup}>
							<Link to={`/articles/${props.articleId}/edit`}>
								<Button btnwidth='3rem' btnheight='1.5rem' btnclasses={[ 'Edit' ]}>
									EDIT
								</Button>
							</Link>
							<Button
								btnwidth='3rem'
								btnheight='1.5rem'
								btnclasses={[ 'Delete' ]}
								onClick={openDeleteHandler}>
								DEL
							</Button>
						</div>
					)}
				</div>
				<span className={classes.ReadMore}>
					<Link to={`/articles/${props.articleId}`}>
						<em>read more...</em>
					</Link>
				</span>
			</div>
		</React.Fragment>
	);
};

export default ArticleCard;

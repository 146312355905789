import React from 'react';

import ArticleForm from '../components/Articles/ArticleForm';

const ArticleNew = props => {
	return (
		<div>
			<h1>Create New Article</h1>
			<ArticleForm isAuthenticated={props.isAuthenticated} />
		</div>
	);
};

export default ArticleNew;

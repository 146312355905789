import React from 'react';

import classes from './GoogleLocation.module.css';

const GoogleLocation = () => {
	return (
		<div className={classes.GoogleAddress}>
			<h3>PT. Inti Dinamis</h3>
			<p>Taman Laguna Blok O2 No.20</p>
			<p>Jatikarya, Jati Sampurna</p>
			<p>Bekasi. 17435</p>
		</div>
	);
};

export default GoogleLocation;

import React from 'react';

import intiDinamisLogo from '../../../assets/images/Inti-Dinamis-Logo.png';
import classes from './Logo.module.css';

const Logo = props => {
	return (
		<div className={classes.Logo}>
			<img src={intiDinamisLogo} alt='Logo Inti Dinamis' style={{ width: props.width }} />
		</div>
	);
};

export default Logo;

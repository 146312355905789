import React from 'react';

import ClientLogo from './ClientLogo';
import classes from './ClientList.module.css';

const ClientList = props => {
	return (
		<div className={classes.ClientList}>
			{props.clients &&
				props.clients.map(client => (
					<ClientLogo key={client.name} imgUrl={client.imgUrl} clientName={client.name} />
				))}
		</div>
	);
};

export default ClientList;

import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import classes from './NavLinks.module.css';

const NavLinks = props => {
	return (
		<ul className={classes.NavLinks}>
			<li>
				<NavLink activeClassName={classes.active} to='/' exact>
					Home
				</NavLink>
			</li>
			<li>
				<NavLink activeClassName={classes.active} to='/tentang-kami'>
					Tentang Kami
				</NavLink>
			</li>
			<li>
				<NavLink activeClassName={classes.active} to='/produk-jasa'>
					Produk & Jasa
				</NavLink>
			</li>
			<li>
				<NavLink activeClassName={classes.active} to='/pengalaman'>
					Pengalaman
				</NavLink>
			</li>
			<li>
				<NavLink activeClassName={classes.active} to='/galeri'>
					Galeri
				</NavLink>
			</li>
			<li>
				<NavLink activeClassName={classes.active} to='/articles'>
					Artikel
				</NavLink>
			</li>
			<li>
				<NavLink activeClassName={classes.active} to='/kontak-kami'>
					Kontak Kami
				</NavLink>
			</li>
			{props.isAuthenticated && (
				<li>
					<NavLink to='/logout'>
						{' '}
						<span style={{ fontWeight: 'bold', color: 'salmon' }}> LOGOUT</span>
					</NavLink>
				</li>
			)}
		</ul>
	);
};

export default withRouter(NavLinks);

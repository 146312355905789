import React from 'react';

import classes from './Intro.module.css';
import whiteboard from '../../assets/homepage/whiteboard.png';

const Intro = () => {
	return (
		<div>
			<div className={classes.Intro}>
				<div className={classes.ImageIntro}>
					<img src={whiteboard} alt='whiteboard' />
				</div>
				<div className={classes.ContentIntro}>
					<h2>MENGELOLA SUMBER DAYA MANUSIA </h2>
					<h2> MENJADI KONTRIBUTOR HEBAT</h2>
					<cite>
						<p>"Hasil ditentukan tindakan.</p>
						<p>Tindakan ditentukan pikiran.</p>
						<p>Pikiran ditentukan keyakinan."</p>
						<p>- Coach John Arif</p>
					</cite>
				</div>
			</div>
		</div>
	);
};

export default Intro;

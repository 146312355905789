import { initStore } from './store';

const configureAuthStore = () => {
	const actions = {
		AUTH_START: currentState => {
			return {
				...currentState,
				authStats: {
					...currentState.authStats,
					isLoading: true,
					error: null
				}
			};
		},
		AUTH_SUCCESS: (currentState, { idToken, userId }) => {
			return {
				...currentState,
				authStats: {
					idToken,
					userId,
					isLoading: false,
					error: null
				}
			};
		},
		AUTH_FAIL: (currentState, error) => {
			return {
				...currentState,
				authStats: {
					...currentState.authStats,
					isLoading: false,
					error: error.message
				}
			};
		},
		AUTH_LOGOUT: currentState => {
			return {
				...currentState,
				authStats: {
					...currentState.authStats,
					idToken: null,
					userId: null
				}
			};
		}
	};

	initStore(actions, {
		authStats: {
			idToken: null,
			userId: null,
			isLoading: false,
			error: null
		}
	});
};

export default configureAuthStore;

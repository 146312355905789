import React, { useEffect } from 'react';

import ContentDesc from '../shared/ContentDesc/ContentDesc';
import GaleriBG from '../assets/BGContentDesc/BG_Galeri.jpg';
import GaleriList from '../components/Galeri/GaleriList';

import galeri1 from '../assets/Galeri/Galeri_1.jpg';
import galeri2 from '../assets/Galeri/Galeri_2.jpg';
import galeri3 from '../assets/Galeri/Galeri_3.jpg';
import galeri4 from '../assets/Galeri/Galeri_4.jpg';
import galeri5 from '../assets/Galeri/Galeri_5.jpg';
import galeri6 from '../assets/Galeri/Galeri_6.jpg';
import galeri7 from '../assets/Galeri/Galeri_7.jpg';
import galeri8 from '../assets/Galeri/Galeri_8.jpg';
import galeri9 from '../assets/Galeri/Galeri_9.jpg';
import galeri10 from '../assets/Galeri/Galeri_10.jpg';
import galeri11 from '../assets/Galeri/Galeri_11.jpg';
import galeri12 from '../assets/Galeri/Galeri_12.jpg';
import galeri13 from '../assets/Galeri/Galeri_13.jpg';
import galeri14 from '../assets/Galeri/Galeri_14.jpg';
import galeri15 from '../assets/Galeri/Galeri_15.jpg';
import galeri16 from '../assets/Galeri/Galeri_16.jpg';
import galeri17 from '../assets/Galeri/Galeri_17.jpg';
import galeri18 from '../assets/Galeri/Galeri_18.jpg';
import galeri19 from '../assets/Galeri/Galeri_19.jpg';
import galeri20 from '../assets/Galeri/Galeri_20.jpg';
import galeri21 from '../assets/Galeri/Galeri_21.jpg';
import galeri22 from '../assets/Galeri/Galeri_22.jpg';
import galeri23 from '../assets/Galeri/Galeri_23.jpg';
import galeri24 from '../assets/Galeri/Galeri_24.jpg';
import galeri25 from '../assets/Galeri/Galeri_25.jpg';
import galeri26 from '../assets/Galeri/Galeri_26.jpg';
import galeri27 from '../assets/Galeri/Galeri_27.jpg';
import galeri28 from '../assets/Galeri/Galeri_28.jpg';
import galeri29 from '../assets/Galeri/Galeri_29.jpg';
import galeri30 from '../assets/Galeri/Galeri_30.jpg';

const Galeri = () => {
	useEffect(() => window.scrollTo(0, 0));
	const title = 'GALERI';
	const galeri = [
		{
			name: 'galeri1',
			backgroundImage: galeri1
		},
		{
			name: 'galeri2',
			backgroundImage: galeri2
		},
		{
			name: 'galeri3',
			backgroundImage: galeri3
		},
		{
			name: 'galeri4',
			backgroundImage: galeri4
		},
		{
			name: 'galeri5',
			backgroundImage: galeri5
		},
		{
			name: 'galeri6',
			backgroundImage: galeri6
		},
		{
			name: 'galeri7',
			backgroundImage: galeri7
		},
		{
			name: 'galeri8',
			backgroundImage: galeri8
		},
		{
			name: 'galeri9',
			backgroundImage: galeri9
		},
		{
			name: 'galeri10',
			backgroundImage: galeri10
		},
		{
			name: 'galeri11',
			backgroundImage: galeri11
		},
		{
			name: 'galeri12',
			backgroundImage: galeri12
		},
		{
			name: 'galeri13',
			backgroundImage: galeri13
		},
		{
			name: 'galeri14',
			backgroundImage: galeri14
		},
		{
			name: 'galeri15',
			backgroundImage: galeri15
		},
		{
			name: 'galeri16',
			backgroundImage: galeri16
		},
		{
			name: 'galeri17',
			backgroundImage: galeri17
		},
		{
			name: 'galeri18',
			backgroundImage: galeri18
		},
		{
			name: 'galeri19',
			backgroundImage: galeri19
		},
		{
			name: 'galeri20',
			backgroundImage: galeri20
		},
		{
			name: 'galeri21',
			backgroundImage: galeri21
		},
		{
			name: 'galeri22',
			backgroundImage: galeri22
		},
		{
			name: 'galeri23',
			backgroundImage: galeri23
		},
		{
			name: 'galeri24',
			backgroundImage: galeri24
		},
		{
			name: 'galeri25',
			backgroundImage: galeri25
		},
		{
			name: 'galeri26',
			backgroundImage: galeri26
		},
		{
			name: 'galeri27',
			backgroundImage: galeri27
		},
		{
			name: 'galeri28',
			backgroundImage: galeri28
		},
		{
			name: 'galeri29',
			backgroundImage: galeri29
		},
		{
			name: 'galeri30',
			backgroundImage: galeri30
		}
	];
	return (
		<React.Fragment>
			<ContentDesc title={title} backgroundImage={GaleriBG} isGradient />
			<GaleriList galeri={galeri} />
		</React.Fragment>
	);
};

export default Galeri;

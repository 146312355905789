import React from 'react';
import { Link } from 'react-router-dom';

import ProdukJasaCard from './ProdukJasaCard';
import classes from './ProdukJasaList.module.css';

const ProdukJasaList = props => {
	return (
		<div className={classes.ProdukJasaList}>
			{props.products.map(product => (
				<Link to={`/produk-jasa/${product.id}`} key={product.id}>
					<ProdukJasaCard
						productName={product.productName}
						brief={product.content.brief}
					/>
				</Link>
			))}
		</div>
	);
};

export default ProdukJasaList;

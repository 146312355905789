import React from 'react';

import classes from './ContentDesc.module.css';
import businessPeople from '../../assets/images/businessperson.png';

const ContentDesc = props => {
	return (
		<React.Fragment>
			<div className={classes.ContentDesc}>
				<div
					className={classes.ContentDescBackground}
					style={{ backgroundImage: `url(${props.backgroundImage || businessPeople})` }}
				/>
				<h1>{props.title}</h1>
				{props.isGradient && <div className={classes.WhiteGradient} />}
				{props.subtitle && <p>{props.subtitle}</p>}
			</div>
			{props.description && (
				<div className={classes.DescriptionContainer}>
					<div className={classes.ContentDescDescription}>{props.description}</div>
					{props.description2 && (
						<div className={classes.ContentDescDescription}>{props.description2}</div>
					)}
					{props.description3 && (
						<div className={classes.ContentDescDescription}>{props.description3}</div>
					)}
					{props.description4 && (
						<div className={classes.ContentDescDescription}>{props.description4}</div>
					)}
				</div>
			)}
		</React.Fragment>
	);
};

export default ContentDesc;

import React, { useEffect } from 'react';

import ContentDesc from '../shared/ContentDesc/ContentDesc';
import MisiIntiDinamis from '../components/TentangKami/MisiIntiDinamis';
import PartnerList from '../components/TentangKami/PartnerList';
import john from '../assets/images/John.jpg';
import gaby from '../assets/images/Gaby.jpg';
import risman from '../assets/images/Risman.jpg';
import TKBG from '../assets/BGContentDesc/BG_Tentang_Kami.jpg';

const TentangKami = () => {
  useEffect(() => window.scrollTo(0, 0));
  const title = 'TENTANG KAMI';
  const subtitle = 'PARTNER ANDA MENUJU ORGANISASI YANG LEBIH BAIK';
  const description = `Inti Dinamis bergerak dalam bidang jasa konsultan organisasi dan pengembangan sumber daya manusia.
   Didirikan tahun 2005 dengan dilandasi intensi untuk menjadi mitra terbaik bagi rekan bisnisnya dalam
   mengelola dan mengembangkan Human Capital untuk menjamin pencapaian visi yang dicanangkan.`;
  const description2 = `Human Capital sebagai asset berharga perusahaan merupakan nilai keseluruhan dari atribut pribadi,
   pengalaman hidup, energi, dan aspek aspek lainnya dalam diri seseorang yang dia pilih untuk diaplikasikan
   dalam lingkungan pekerjaannya. Untuk menjadikan organisasi Anda sebagai organisasi pemenang dengan
	mengelola dan mengembangkan Human Capital untuk menjadi daya saing organisasi.`;

  const partners = [
    {
      id: 'pt01',
      name: 'JOHN ARIF PURBA, ACC',
      title: 'Psychologist, Associate Certified Coach, Master NLP Practitioner',
      description: `<p>Seorang psikolog yang berpengalaman puluhan tahun dalam bidang pengembangan sumber daya manusia. Dalam profesinya sebagai seorang konsultan telah banyak membantu perusahaan besar dalam menyusun strategi pengembangan SDM serta memfasilitasi proses pembentukan sistem pengelolaan SDM yang terintegrasi dengan Visi Misi dan Values Perusahaan.
			<br/><br/>Sebagai seorang certified professional coach, ia melengkapi kompetensi tersebut dengan menjadi seorang Master Practitioner dibidang Neuro Linguistic Programming (NLP) sehingga kegiatan coaching yang dia lakukan mampu memberi dampak perubahan signifikan pada kliennya.
			<br/><br/>Berbagai kegiatan pelatihan khususnya yang terkait dengan pengembangan sumber daya manusia telah banyak dilakukan dengan hasil yang memuaskan.  Konsep perubahan perilaku yang digunakan sebagai dasar utama untuk meningkatkan kinerja manusia menjadi semakin kuat dampaknya saat proses fasilitasi kegiatan pelatihan dilakukan dengan menggunakan berbagai teknik pemrograman pikiran manusia.
			</p>`,
      image: john,
    },
    {
      id: 'pt02',
      name: 'GABY ANNIWATI',
      title: 'Psychologist, Certified Professional Coach, Clinical Hypnotherapist',
      description: `<p>Berpengalaman selama lebih dari duapuluh tahun dalam bidang pengelolaan SDM. Sebagai seorang HR Professional ia banyak membantu klien  dalam program penyediaan SDM dan pengembangan SDM. Recruitment dan assessment merupakan salah satu kompetensi yang dia terus kembangkan sehingga kualitas jasa yang diberikan pada klien terus meningkat dari waktu ke waktu. 
			<br/><br/>Coaching merupakan salah satu kompetensi yang ia miliki. Berbasis sertifikasi coach professional ia banyak membantu pemimpin dalam berbagai perusahaan untuk meningkatkan kualitas hasil kerja dan kualitas kepemimpinan melalui kegiatan coaching. Metodologi coaching juga dia gunakan dalam proses pemberian umpan balik kepada klien atas hasil pemeriksaan psikologis yang dilaksanakan.
			</p>`,
      image: gaby,
    },
    {
      id: 'pt03',
      name: 'RISMAN PURBA',
      title: 'BrainPower® Trainer, Certified Professional Coach',
      description: `<p>Seorang praktisi di bidang modifikasi perilaku dalam rangka peningkatan kompetensi dan produktifitas manusia. Berpengalaman dalam memfasilitasi berbagai jenis pelatihan pengembangan SDM baik dalam skala ruang kelas maupun dalam skala besar di lapangan terbuka. Melalui pendalaman tentang dinamika perilaku manusia dan didukung pengalaman puluhan tahun sebagai seorang trainer, ia telah berhasil mengembangkan sebuah konsep pengembangan kualitas individu yang disebut sebagai BrainPower.
			<br/><br/>Dengan menggunakan konsep BrainPower telah dihasilkan berbagai modul pelatihan yang terbukti ampuh untuk menghasilkan manusia-manusia yang lebih efektif dan lebih produktif dalam mencapai tujuannya. Kombinasi konsep BrainPower dengan metodologi coaching membuat dia mampu memberi layanan coaching yang sangat provokatif untuk membuat klien lebih terpicu melakukan peningkatan kualitas dirinya.</p>`,
      image: risman,
    },
  ];
  return (
    <div>
      <ContentDesc
        title={title}
        subtitle={subtitle}
        description={description}
        description2={description2}
        backgroundImage={TKBG}
        isGradient
      />
      <MisiIntiDinamis />
      <PartnerList partners={partners} />
    </div>
  );
};

export default TentangKami;

import React, { useReducer, useEffect } from 'react';
import { validate } from '../../util/validator';
import classes from './Input.module.css';

const inputReducer = (state, action) => {
	switch (action.type) {
		case 'ONCHANGE':
			return {
				...state,
				value: action.value,
				isValid: validate(action.value, action.validators)
			};
		case 'ONTOUCH':
			return {
				...state,
				isTouched: true
			};
		default:
			return state;
	}
};

const Input = props => {
	const [ state, dispatch ] = useReducer(inputReducer, {
		value: props.value || '',
		isValid: props.isValid || false,
		isTouched: false
	});

	const onChangeHandler = event => {
		dispatch({
			type: 'ONCHANGE',
			value: event.target.value,
			validators: props.validators
		});
	};

	const onTouchHandler = () => {
		dispatch({ type: 'ONTOUCH' });
	};

	const { id, onInput } = props;
	const { value, isValid } = state;

	useEffect(
		() => {
			onInput && onInput(id, value, isValid);
		},
		[ id, value, onInput, isValid ]
	);

	let inputElement = null;
	switch (props.inputtype) {
		case 'input':
			inputElement = (
				<input
					className={classes.InputElement}
					type={props.type}
					value={state.value}
					onChange={onChangeHandler}
					onBlur={onTouchHandler}
					placeholder={props.placeholder}
				/>
			);
			break;
		case 'textarea':
			inputElement = (
				<textarea
					className={classes.InputElement}
					value={state.value}
					onChange={onChangeHandler}
					onBlur={onTouchHandler}
					rows={props.rows || '5'}
					cols={props.cols || '30'}
					placeholder={props.placeholder}
				/>
			);
			break;
		default:
			inputElement = (
				<input
					className={classes.InputElement}
					type={props.type}
					value={state.value}
					onChange={onChangeHandler}
					onBlur={onTouchHandler}
					placeholder={props.placeholder}
				/>
			);
	}

	return (
		<div className={`${classes.Input} ${!state.isValid && state.isTouched && classes.Invalid}`}>
			{props.label && <label className={classes.Label}>{props.label}</label>}
			{inputElement}
			{!state.isValid && state.isTouched && <p>{props.errortext}</p>}
		</div>
	);
};

export default Input;

import React from 'react';

import classes from './Address.module.css';

const Address = () => {
	return (
		<div className={classes.Address}>
			<h3>PT Inti Dinamis</h3>
			<p>Taman Laguna Blok O2 No.20</p>
			<p>Jati Sampurna</p>
			<p>Bekasi 17435</p>
			<p>0811 855 212 / 0812 994 3619</p>
			<p>john.purba@intidinamis.com</p>
		</div>
	);
};

export default Address;

import React, { useEffect } from 'react';

import HubungiKami from '../components/KontakKami/HubungiKami';
import ContactLink from '../components/KontakKami/ContactLink';
import ContactForm from '../components/KontakKami/ContactForm';
import GoogleLocation from '../components/KontakKami/GoogleLocation';

import classes from './KontakKami.module.css';

const KontakKami = () => {
	useEffect(() => window.scrollTo(0, 0));
	return (
		<div>
			<HubungiKami />
			<div className={classes.ContactUs}>
				<GoogleLocation />
				<ContactLink />
			</div>
			<ContactForm />
		</div>
	);
};

export default KontakKami;

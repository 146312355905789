import React, { useState, useEffect, useCallback } from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { withRouter, Redirect } from 'react-router-dom';
import { useForm } from '../../shared/hooks/form-hook';
import { useStore } from '../../store/store';

import axios from '../../axios-articles';
import Input from '../../shared/UI/Input/Input';
import Button from '../../shared/UI/Button/Button';
import Spinner from '../../shared/UI/Spinner/Spinner';
import { VALIDATOR_REQUIRE } from '../../shared/util/validator';
import withHttpErrorHandler from '../../shared/HOC/withHttpErrorHandler';

import classes from './ArticleForm.module.css';

const ArticleForm = props => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ redirector, setRedirector ] = useState(null);
	const [ formElement, setFormElement ] = useState(<Spinner />);
	const state = useStore()[0];
	const [ formState, onInputHandler ] = useForm(
		{
			title: {
				value: '',
				isValid: false
			},
			brief: {
				value: '',
				isValid: false
			},
			article: {
				value: '',
				isValid: true
			}
		},
		false
	);

	const { isAuthenticated } = props;

	const ckEditor = useCallback(
		(event, editor) => {
			const articleData = editor.getData();
			console.log(editor);
			onInputHandler('article', articleData, true);
		},
		[ onInputHandler ]
	);

	useEffect(
		() => {
			let mounted = true;
			let timer = null;
			if (!isAuthenticated) {
				if (mounted) {
					timer = setTimeout(() => {
						setRedirector(<Redirect to='/' />);
					}, 100);
				}
			} else {
				setRedirector(null);

				setFormElement(
					<React.Fragment>
						<Input
							id='title'
							inputtype='input'
							type='text'
							label='Title'
							onInput={onInputHandler}
							errortext='Please enter a valid value.'
							validators={[ VALIDATOR_REQUIRE() ]}
						/>
						<Input
							id='brief'
							inputtype='textarea'
							label='Subject'
							rows='2'
							cols='30'
							onInput={onInputHandler}
							errortext='Please enter a valid Subject.'
							validators={[ VALIDATOR_REQUIRE() ]}
						/>
						<CKEditor
							editor={ClassicEditor}
							onChange={ckEditor}
							data={formState.inputs.article.value}
						/>

						<div className={classes.SubmitBtn}>
							<Button
								btnwidth='7rem'
								btnheight='2rem'
								btnclasses={[ 'Form' ]}
								type='submit'
								disabled={!formState.isValid}>
								Add Article
							</Button>
						</div>
					</React.Fragment>
				);
			}
			return () => {
				mounted = false;
				clearTimeout(timer);
			};
		},
		[
			isAuthenticated,
			formState.isValid,
			onInputHandler,
			ckEditor,
			formState.inputs.article.value
		]
	);

	const onSubmitHandler = event => {
		event.preventDefault();
		setIsLoading(true);
		const newArticle = {
			title: formState.inputs.title.value,
			brief: formState.inputs.brief.value,
			article: formState.inputs.article.value
		};
		axios
			.post(`/articles.json?auth=${state.authStats.idToken}`, newArticle)
			.then(() => {
				setIsLoading(false);
				props.history.push('/');
			})
			.catch(err => {
				setIsLoading(false);
				console.log(err);
			});
	};

	let fullContent = (
		<React.Fragment>
			{redirector}
			{formElement}
		</React.Fragment>
	);

	if (isLoading) {
		fullContent = <Spinner />;
	}

	return (
		<form className={classes.ArticleForm} onSubmit={onSubmitHandler}>
			{fullContent}
		</form>
	);
};

export default withHttpErrorHandler(withRouter(ArticleForm), axios);

import React, { useEffect } from 'react';

import ContentDesc from '../shared/ContentDesc/ContentDesc';
import ClientList from '../components/Pengalaman/ClientList';
import PengBG from '../assets/BGContentDesc/BG_Pengalaman.jpg';

import LogoClient from '../assets/client-logo/LogoClient.jpg';

const Pengalaman = () => {
	useEffect(() => window.scrollTo(0, 0));
	const title = 'PENGALAMAN';
	const description = `Kami berpengalaman memberikan layanan pengembangan sumber daya manusia, pengembangan
	organisasi, pengembangan pemimpin, pengembangan strategi usaha, pengembangan budaya, dan berbagai
	proyek lainnya kepada klien-klien kami dalam berbagai industri seperti otomotif, pembiayaan, transportasi,
	restoran, kesehatan, pemerintahan, hiburan, cat, media, agribisnis, dan lainnya.`;

	const clients = [
		{
			name: 'LogoClient',
			imgUrl: LogoClient
		}
	];

	return (
		<div>
			<ContentDesc title={title} description={description} backgroundImage={PengBG} isGradient />
			<ClientList clients={clients} />
		</div>
	);
};

export default Pengalaman;

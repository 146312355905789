import React from 'react';
import reactHtmlParser from 'react-html-parser';

import classes from './PartnerProfile.module.css';

const PartnerProfile = props => {
	return (
		<li className={classes.PartnerProfile}>
			<div className={classes.PartnerPhoto}>
				<img src={props.imgUrl} alt={props.name} />
			</div>
			<div className={classes.PartnerDesc}>
				<h2>{props.name}</h2>
				<p>{props.title}</p>
				{/* <p>{props.partnerDesc}</p> */}
				{reactHtmlParser(props.partnerDesc)}
			</div>
		</li>
	);
};

export default PartnerProfile;

import { useReducer, useCallback } from 'react';

const formReducer = (state, action) => {
	switch (action.type) {
		case 'INPUTCHANGE':
			let formIsValid = true;
			for (const key in state.inputs) {
				if (key === action.id) {
					formIsValid = formIsValid && action.isValid;
				} else {
					formIsValid = formIsValid && state.inputs[key].isValid;
				}
			}
			return {
				...state,
				inputs: {
					...state.inputs,
					[action.id]: { value: action.value, isValid: action.isValid }
				},
				isValid: formIsValid
			};
		case 'SETDATA':
			return {
				inputs: action.inputs,
				isValid: action.formIsValid
			};
		default:
			return state;
	}
};

export const useForm = (initialInputs, initialFormValidity) => {
	const [ formState, dispatch ] = useReducer(formReducer, {
		inputs: initialInputs,
		isValid: initialFormValidity
	});

	const onInputHandler = useCallback((id, value, isValid) => {
		dispatch({ type: 'INPUTCHANGE', id: id, value: value, isValid: isValid });
	}, []);

	const setFormData = useCallback((inputData, formValidity) => {
		dispatch({ type: 'SETDATA', inputs: inputData, formIsValid: formValidity });
	}, []);

	return [ formState, onInputHandler, setFormData ];
};

export default useForm;

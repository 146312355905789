import React from 'react';

import classes from './MenuBtn.module.css';

const MenuBtn = props => {
	return (
		<button className={classes.BurgerIcon} onClick={props.onClick}>
			<span />
			<span />
			<span />
		</button>
	);
};

export default MenuBtn;

import React from 'react';

import classes from './CopyrightBar.module.css';

const CopyrightBar = () => {
	return (
		<div className={classes.CopyrightBar}>
			<footer>Copyright PT Inti Dinamis 2020</footer>
		</div>
	);
};

export default CopyrightBar;

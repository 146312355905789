import React from 'react';

import careerPath from '../../assets/images/careerpath.jpg';
import classes from './ProdukJasaCard.module.css';

const ProdukJasaCard = props => {
	return (
		<div className={classes.ProdukJasaCard}>
			<div
				className={classes.ProdukJasaBackground}
				style={{ backgroundImage: `url(${careerPath})` }}
			/>
			<div className={classes.ProdukJasaBackgroundColor} />
			<div className={classes.ProdukJasaBrief}>
				{props.brief}{' '}
				<span
					style={{
						color: 'white',
						fontStyle: 'italic',
						position: 'absolute',
						bottom: '0',
						right: '0',
						padding: '0 1rem 1rem 0'
					}}>
					[click to learn more]
				</span>
			</div>
			<h2>{props.productName}</h2>
		</div>
	);
};

export default ProdukJasaCard;

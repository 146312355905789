import React from 'react';
import { NavLink } from 'react-router-dom';
import classes from './ProdukJasaNav.module.css';

const ProdukJasaNav = props => {
	return (
		<div className={classes.ProdukJasaNav}>
			{props.products.map(prod => (
				<NavLink
					to={`/produk-jasa/${prod.id}`}
					key={prod.id}
					activeClassName={classes.active}>
					{prod.content.header}
				</NavLink>
			))}
		</div>
	);
};

export default ProdukJasaNav;

import React from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import Backdrop from '../Backdrop/Backdrop';
import classes from './Modal.module.css';

const ModalOverlay = props => {
	const content = (
		<div
			className={`${classes.Modal} ${classes[props.modalClass]}`}
			style={props.style}
			onClick={props.clicked}>
			<header className={`${classes.ModalHeader} ${classes[props.modalHeaderClass]}`}>
				<h2>{props.header}</h2>
			</header>
			<form onSubmit={props.onSubmit ? props.onSubmit : e => e.preventDefault()}>
				<div className={`${classes.ModalContent} ${classes[props.modalContentClass]}`}>
					{props.children}
				</div>
				<footer className={`${classes.ModalFooter} ${classes[props.modalFooterClass]}`}>
					{props.footer}
				</footer>
			</form>
		</div>
	);
	return ReactDOM.createPortal(content, document.getElementById('modal-hook'));
};

const Modal = props => {
	return (
		<React.Fragment>
			{props.show && <Backdrop onClick={props.onCancel} />}
			<CSSTransition
				in={props.show}
				mountOnEnter
				unmountOnExit
				timeout={200}
				classNames='modal'>
				<ModalOverlay {...props} />
			</CSSTransition>
		</React.Fragment>
	);
};

export default Modal;

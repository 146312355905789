import React, { useEffect } from 'react';

import AdminAuthForm from '../components/Admin/AdminAuthForm';
import classes from './AdminAuth.module.css';

const AdminAuth = () => {
	useEffect(() => window.scrollTo(0, 0));
	return (
		<React.Fragment>
			<h1 className={classes.AdminAuthHeader}>Admin Login</h1>
			<AdminAuthForm />
		</React.Fragment>
	);
};

export default AdminAuth;

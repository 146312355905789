import React from 'react';

import classes from './ClientLogo.module.css';

const ClientLogo = props => {
	return (
		<div className={classes.ClientLogo}>
			<img src={props.imgUrl} alt={props.clientName} />
		</div>
	);
};

export default ClientLogo;

import React from 'react';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import classes from './ContactLink.module.css';

const ContactLink = () => {
	return (
		<div className={classes.ContactLink}>
			<div className={classes.Email}>
				<span>
					<MailIcon />
				</span>
				<span>john.purba@intidinamis.com</span>
			</div>
			<div className={classes.Phone}>
				<span>
					<PhoneIcon />
				</span>
				<span>0811 855 212 / 0812 994 3619</span>
			</div>
		</div>
	);
};

export default ContactLink;

import React, { useState } from 'react';
import { useForm } from '../../shared/hooks/form-hook';
import { useStore } from '../../store/store';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import {
	VALIDATOR_REQUIRE,
	VALIDATOR_MINLENGTH,
	VALIDATOR_ADMINAUTH,
	VALIDATOR_EMAIL
} from '../../shared/util/validator';

import Spinner from '../../shared/UI/Spinner/Spinner';
import Input from '../../shared/UI/Input/Input';
import Button from '../../shared/UI/Button/Button';
import classes from './AdminAuthForm.module.css';

const AdminAuthForm = props => {
	const [ state, dispatch ] = useStore();
	const [ isLogin, setIsLogin ] = useState(true);
	const [ loginState, onLoginHandler ] = useForm(
		{
			email: {
				value: '',
				isValid: false
			},
			adminPassword: {
				value: '',
				isValid: false
			}
		},
		false
	);
	const [ signupState, onSignupHandler ] = useForm(
		{
			email: {
				value: '',
				isValid: false
			},
			adminPassword: {
				value: '',
				isValid: false
			},
			adminAuthentication: {
				value: '',
				isValid: false
			}
		},
		false
	);

	const switchAuthHandler = () => {
		setIsLogin(prevState => !prevState);
	};

	const onSubmitHandler = event => {
		event.preventDefault();
		dispatch('AUTH_START');

		if (isLogin) {
			const loginData = {
				email: loginState.inputs.email.value,
				password: loginState.inputs.adminPassword.value,
				returnSecureToken: true
			};

			axios
				.post(
					`https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyA_2w0R32pxF0nwe-9KjsogO8NbzepEv6k`,
					loginData
				)
				.then(res => {
					dispatch('AUTH_SUCCESS', {
						idToken: res.data.idToken,
						userId: res.data.localId
					});
					setTimeout(() => {
						dispatch('AUTH_LOGOUT');
						localStorage.removeItem('idToken');
						localStorage.removeItem('expireMoment');
						localStorage.removeItem('userId');
					}, res.data.expiresIn * 1000);
					const expireMoment = new Date(new Date().getTime() + res.data.expiresIn * 1000);
					localStorage.setItem('idToken', res.data.idToken);
					localStorage.setItem('expireMoment', expireMoment);
					localStorage.setItem('userId', res.data.localId);
					props.history.push('/');
				})
				.catch(err => {
					dispatch('AUTH_FAIL', err);
				});
		} else {
			const signupData = {
				email: signupState.inputs.email.value,
				password: signupState.inputs.adminPassword.value,
				returnSecureToken: true
			};

			axios
				.post(
					'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyA_2w0R32pxF0nwe-9KjsogO8NbzepEv6k',
					signupData
				)
				.then(res => {
					dispatch('AUTH_SUCCESS', {
						idToken: res.data.idToken,
						userId: res.data.localId
					});
					setTimeout(() => {
						dispatch('AUTH_LOGOUT');
						localStorage.removeItem('idToken');
						localStorage.removeItem('expireMoment');
						localStorage.removeItem('userId');
					}, res.data.expiresIn * 1000);
					const expireMoment = new Date(new Date().getTime() + res.data.expiresIn * 1000);
					localStorage.setItem('idToken', res.data.idToken);
					localStorage.setItem('expireMoment', expireMoment);
					localStorage.setItem('userId', res.data.localId);
					props.history.push('/');
					props.history.push('/');
				})
				.catch(err => {
					dispatch('AUTH_FAIL', err);
					console.log(err);
				});
		}
	};

	let authContent = (
		<React.Fragment>
			<Input
				inputtype='input'
				type='text'
				label='Email'
				id='email'
				onInput={isLogin ? onLoginHandler : onSignupHandler}
				validators={[ VALIDATOR_REQUIRE(), VALIDATOR_EMAIL() ]}
				errortext={
					isLogin ? (
						'Please input a valid Email'
					) : (
						'Email must contain minimum 6 characters'
					)
				}
			/>
			<Input
				inputtype='input'
				type='password'
				label='Password'
				id='adminPassword'
				onInput={isLogin ? onLoginHandler : onSignupHandler}
				validators={[ VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(6) ]}
				errortext={
					isLogin ? (
						'Please input a valid password'
					) : (
						'Password must contain minimum 6 characters'
					)
				}
			/>
			{!isLogin && (
				<React.Fragment>
					<Input
						inputtype='input'
						type='password'
						label='Admin Authentication'
						id='adminAuthentication'
						onInput={isLogin ? onLoginHandler : onSignupHandler}
						validators={[ VALIDATOR_REQUIRE(), VALIDATOR_ADMINAUTH() ]}
						errortext='Please input a valid authentication'
					/>
				</React.Fragment>
			)}
			<div className={classes.ButtonContainer}>
				<Button
					btnwidth='5rem'
					btnheight='2rem'
					disabled={isLogin ? !loginState.isValid : !signupState.isValid}>
					{isLogin ? 'LOGIN' : 'SIGN UP'}
				</Button>
			</div>
			<p onClick={switchAuthHandler} className={classes.Switch}>
				Switch To {isLogin ? 'SIGN UP' : 'LOGIN'}
			</p>
		</React.Fragment>
	);

	if (state.authStats.isLoading) {
		authContent = <Spinner />;
	}

	return (
		<form className={classes.AdminAuthForm} onSubmit={onSubmitHandler}>
			{authContent}
		</form>
	);
};

export default withRouter(AdminAuthForm);

import React from 'react';

import CopyrightBar from './CopyrightBar/CopyrightBar';
import Logo from '../UI/Logo/Logo';
import Address from './Address/Address';
import classes from './Footer.module.css';

const Footer = () => {
	return (
		<React.Fragment>
			<div className={classes.Footer}>
				<Logo width='12rem' />
				<Address />
			</div>
			<CopyrightBar />
		</React.Fragment>
	);
};

export default Footer;
